<template>
  <div class="pol">
    <div class="sub-title f-b">{{app_name}}用户隐私政策</div>
    <div class="desc">
      <p class="f-b">更新日期：2022年6月27日</p>
      <p class="f-b">生效日期：2022年6月27日</p>
      <div class="desc">
        <p class="f-b">序言：</p>
        <p class="f-b">
          欢迎您使用佳伴！佳伴是由海南悠蓝网络科技有限公司（注册地址：深圳市南山区月亮湾大道2076号高科集团大楼7楼78087）及其关联公司（以下可简称“我们”）运营。我们非常重视对您个人信息的保护，并依据《中华人民共和国网络安全法》、《信息安全技术个人信息安全规范》及相关法律法规制定了《佳伴隐私政策》（以下可简称“本政策”或“本隐私政策”）。</p>
      </div>
      <div class="lists">
        <p class="f-b">我们希望通过本政策帮助您了解以下内容：</p>
        <p>一、我们如何收集您的个人信息</p>
        <p>二、我们收集和使用您的个人信息的目的</p>
        <p>三、我们如何使用您的个人信息</p>
        <p>四、我们如何使用Cookie 和同类技术</p>
        <p>五、我们如何共享、转让、委托处理、公开披露您的个人信息</p>
        <p>六、我们如何保护您的个人信息</p>
        <p>七、您如何管理自己的个人信息（您的权利）</p>
        <p>八、我们如何处理未成年人的个人信息</p>
        <p>九、您个人信息的存储地点及存储时间</p>
        <p>十、对第三方责任的声明</p>
        <p>十一、本政策的适用及修</p>
        <p>十二、如何联系我们</p>
        <p>十三、本政策中关键词说明</p>
        <p>十四、其他说明</p>
        <p>十五、《附件一：佳伴Android 版本第三方SDK/API目录》</p>
        <p>
          我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全。我们在本隐私政策中所提及的收集、使用、共享、转让及管理的个人信息包括加粗及带有下划线标识的个人敏感信息以及其他的个人信息。我们致力于维持您对我们的信任，并恪守以下原则：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
        </p>
        <p>请您在使用我们的产品或服务前，仔细阅读并确认您已经充分理解本政策所写明的内容，您点击APP隐私政策协议弹窗的“同意”后即视为您接受本政策的内容。
        </p>

        <p class="f-b">一、我们如何收集您的个人信息
        </p>
        <p> （一）用户注册和登录功能
        </p>
        <p>
          1、当您选择注册佳伴时，您可以通过第三方账号（例如微信等）注册、登录产品，您需要授权我们获取您登录所使用的第三方账号的公开信息（例如头像、昵称、性别等），并将该账号与您的产品账号进行绑定。如果您不同意授权或拒绝我们获取上述信息，您将无法通过第三方账号登录产品，但不影响您正常使用产品的其他功能。
        </p>
        <p>
          2、完成产品账号注册后，您可以自行决定是否需要完善您的其他账户信息头像、昵称、密码、性别、职业、所在城市、家乡、个人简介、个人喜好等账号信息。如果您完善以上额外信息，将有助于我们为您提供更好的服务和体验，但如果您不提供上述信息，将无法为您提供有效的平台服务。
        </p>
        <p class="f-b">（二）实名认证
        </p>
        <p>

          在您申请提现或使用其他需实名认证的功能或服务时，为符合法律法规相关要求，保障你或其他用户的安全，或维护功能/服务的安全稳定，需要你提供真实身份信息（真实姓名、身份证件号码或人脸信息，根据实际情况可能有所不同）以完成实名认证。
        </p>
	<p>
	在实名认证的过程中，可能需要由提供认证服务的第三方认证机构核验你的真实身份信息。这些信息仅供完成实名认证的目的，或其他法律法规所规定的用途，未经你明示授权不会用作其他目的。你可以拒绝提供，但将可能无法使用必须实名认证才可使用的功能或相关服务。不能使用此类功能或服务，不影响你使用其他功能与服务。
        </p>
	<p>基于法律法规的相关要求，你的实名认证信息还可能被我们用于青少年/儿童保护、防沉迷相关的产品或服务。
        </p>
        <p class="f-b">（三）账号公开信息
        </p>
        <p>我们会在你注册佳伴时为你的帐号生成佳伴号，佳伴号是你的帐号的公开识别符号。
        </p>
        <p>你可以自主填写性别、年龄、地区及个人介绍来完善你的信息。不填写信息不会影响你使用佳伴帐号及服务。
        </p>
        <p>你的部分个人信息（例如性别、年龄、个人介绍等）、公开发布的佳伴动态将会在帐号的个人主页中公开展示。
        </p>
        <p>我们发布违规处罚公告时，会公布违规帐号的昵称等信息。
        </p>
        <p class="f-b">（四）实现音视频及图文发布等功能或服务
        </p>
        <p>您主动发布音视频（相册）、评论信息、图文等时，我们将收集音视频、图文等相应信息，并展示您的昵称、头像、发布信息内容等。
        </p>
        <p>1、您发布音视频或进行音视频通话时，我们会请求您授权相机、照片、麦克风等权限。如果您不同意或拒绝授权提供，将无法使用此功能，但不影响您正常使用产品的其他功能。
        </p>
        <p>
          2、您使用其他需要进行身份认证的功能或服务时，根据中华人民共和国相关法律法规规定，您需要通过平台真实性认证（包括但不限于视频认证、真实姓名、手机号码、身份证号等个人敏感信息），用于完成实名认证。如果您不提供上述信息或信息不真实，将无法使用此功能，但不影响您正常使用产品的其他功能。
        </p>
        <p class="f-b">（五）实现互动交流功能或服务
        </p>
        <p>1、您主动关注您感兴趣的用户账号并与其他账号进行交流互动、进行评论或分享内容时，我们会收集您关注的账号，并向您展示您关注账号发布内容。
        </p>
        <p>2、您主动点赞、评论或分享您感兴趣的图文广播等信息时，我们会收集您点赞、评论或分享的内容，并向您点赞或评论的用户进行展示或推送通知。
        </p>
        <p>3、您主动使用虚拟财产与其他用户交互时，我们将收集您的头像、昵称及打赏的数据，并向其他用户进行展示。
        </p>
        <p class="f-b">（六）运营与安全运行
        </p>
        <p>我们致力于为你提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护相关产品或服务的正常稳定运行，保护你或其他用户或公众的安全及合法利益，我们会收集如下必要信息：
        </p>
        <p>（1）为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、GAID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、硬件序列号、IP 地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志、传感器信息等数据。
        </p>
        <p>（2）为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
        </p>
        <p>（3）我们可能使用你的帐号信息、设备信息、服务日志信息，以及我们的关联方、合作方获得你授权或依法可以提供给我们的信息，用于判断帐号安全、交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
        </p>
        <p class="f-b">（七）消息通知
        </p>
        <p>你知悉并同意，我们在运营中可能会通过你在使用产品及/或服务的过程中所提供的联系方式（例如：电话号码），向你同时发送一种或多种类型的通知，例如消息告知、身份验证、安全验证、用户使用体验调研；此外，我们也可能会以短信、电话的方式，为你提供你可能感兴趣的服务、功能或活动等商业性信息。但请你放心，如你不愿接受这些信息，你可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
        </p>
        <p>我们会通过你的设备的系统通知，向你推送你的帐号动态、你关注的用户或内容动态、热点内容等，你可选择在“我的-设置”或设备中关闭佳伴的通知功能，或通过佳伴提供的通知设置功能选择你希望接收的信息。
        </p>
        <p class="f-b">（八）身份验证根据法律法规及监管要求，当您使用产品的兑消等类似功能时，需要您提供有效的支付宝平台真实身份账号、以完成身份基本信息多重交叉验证。如果您不提供前述信息，将无法使用此功能，但不影响您正常使用产品的其他功能。
        </p>
        <p class="f-b">（九）处理用户间投诉、举报等纠纷时，为了客观公正快速处理举报事宜。我们可能会调用相关数据进行佐证。
        </p>
        <p class="f-b">（十）我们从第三方获得的个人信息
        </p>
        <p>1、其他用户因使用我们的产品而被我们收集的信息，例如其他用户发布的信息中可能含有您的部分个人信息（如：在评论、留言、发布图文、音视频中涉及到与您相关的信息）。
        </p>
        <p>
          2、在您使用产品提供的身份认证功能时，我们会根据相关法律法规规定和/或该身份认证功能（如芝麻信用）所必需，我们将采用行业内通行的方式及尽最大的商业努力来保护您个人敏感信息的安全。如果您不提供这些信息，您将可能无法获得相关服务，但不影响您正常使用其他功能。
        </p>
        <p>
          为了您能够及时获知我们的优惠活动，了解最新的产品信息，在一些活动中我们将通过问卷调查或其他的形式收集您的姓名、手机号码、地区、身份、邮箱、微信号、QQ号并使用相应信息进行营销。当您填写问卷或表格等内容，即代表您同意我们收集上述信息并同意我们通过微信、电话、邮箱向您告知我们的优惠活动、向您推荐我们的产品，如您不同意我们收集及使用您的姓名、手机号码、地区、身份、邮箱、微信号、QQ号，请您不要填写问卷、表格等内容，这不会影响您使用本平台其他服务。
        </p>
        <p>以上部分信息属于敏感信息，如果您拒绝提供该信息，我们将停止对该信息的收集，您可能因此无法使用与该信息相关的服务，但不影响您使用我们提供的其他服务。
        </p>
        <p>
          请您注意，在您使用本平台服务过程中，为了保障您的账户安全，同时为了让您更好的了解本平台的服务、参与本平台开展的各项活动，在您突然中断服务使用或长时间停止使用本平台服务或本平台开展的各种优惠活动、我们需要向您进行营销的情况下，我们会通过您留存的手机号码以多种方式向您了解情况或告知活动信息（包括但不限于向您发送信息或由电话客服直接与您联系等)。
        </p>
        <p class="f-b"> （十一）我们的APP权限获取说明
        </p>
        <p>在您使用我们的产品或服务时，我们可能需要向您申请下列与个人信息相关的系统敏感权限：
        </p>
        <p>
          1、位置权限：当您安装或运行佳伴APP时，我们会向您申请获取此权限，目的是为了基于您所在地点位置为您提供推荐服务。若您不开启此权限，您可能因此无法使用我们提供的部分与位置信息相关的服务，但不影响您继续使用我们提供的其他服务。
        </p>
        <p>
          2、相机权限：当您使用佳伴APP申请服务时，您需要开通相机权限，在身份验证的场景下，需要调用您的摄像头进行身份证的拍摄。在需要人脸识别的场景下，则需要调用您的摄像头进行人脸的拍摄。若您不开启此权限，您将无法使用与身份验证或人脸识别相关的功能，但不影响您继续使用我们提供的其他服务。
        </p>
        <p>
          2、读写外部存储权限：当您使用佳伴APP申请服务时，您需要通过开启存储权限保存视频或文件或图片（包括您拍摄的身份证图片、人脸图片）。若您不开启此权限，您将无法使用与身份验证或人脸识别相关的功能，但不影响您继续使用我们提供的其他服务。
        </p>
        <p>3、打电话权限：当您使用佳伴APP申请服务时或申请完成后，您可以选择开通此权限，以便您可以给我们的客服人员打电话。如您选择不开启此权限，您将无法使用给客服人员打电话相关的功能，但不影响您继续使用我们提供的其他服务。
        </p>
        <p>4、音频录制权限：当您使用佳伴APP申请服务时，您需要开启音频录制权限，在视频认证的场景下，需要调用此权限收集您的语音内容。若您不开启此权限，您将无法使用视频认证相关的功能，但不影响您继续使用我们提供的其他服务。
        </p>
        <p>除上述权限之外，您可自主选择是否额外授予佳伴APP其他的系统权限。
        </p>
        <p class="f-b">（十二）我们的APP中接入的第三方SDK/API目录
        </p>
        <p>
          为了使您能够使用应用中的服务及功能，我们的应用中会接入以下第三方SDK/API。第三方SDK/API相关技术由与我们合作的第三方机构提供，我们仅能知悉其要求获得授权的权限。第三方机构将会需要收集您的信息，我们无法获取您的信息，除非第三方机构明确向我们披露的要收集的敏感信息。第三方机构要求获得的权限以及明确向我们披露的收集的敏感信息，详见附件一。
        </p>
        <p>
          我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <p class="f-b">（十三）无需征得同意收集和使用的个人信息</p>

        <p>根据法律法规及相关国家标准，我们在下列任意一种或多种情形下收集和使用您的个人信息无需征得您的授权同意，且我们可能不会响应您提出的更改、修改、删除、注销、撤回同意、索取信息的请求：</p>
        <p>1、与国家安全、国防安全直接相关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您同意的；</p>
        <p>5、所收集的您的个人信息是您自行向社会公众公开的；</p>
        <p>6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>7、根据您的要求签订或履行合同所必需的；</p>
        <p>8、用于维护产品的安全稳定运行所必需的，例如发现、处置产品故障；</p>
        <p>9、为合法的新闻报道所必需的；</p>
        <p>10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>11、法律法规规定及相关国家标准的其他情形。</p>


        <p class="f-b">二、我们收集和使用您的个人信息的目的</p>

        <p class="f-b">- 我们仅会出于本政策所述的以下目的，收集和使用您的个人信息</p>

        <p>1、保障产品服务能够正常运行；</p>
        <p>2、保障产品服务、用户使用的安全；</p>
        <p>3、实现个性化产品功能或服务需求；</p>
        <p>4、向您推荐您可能感兴趣的资讯、活动等；</p>
        <p>5、评估、改善投放和其他促销及推广活动的效果；</p>
        <p>6、管理软件，例如，软件认证、软件升级等；</p>
        <p>7、遵循法律法规及国家标准的规定。
        </p>
        <p>当我们要将个人信息用于本政策未载明的其它用途时，会事先征求您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
        </p>
        <p class="f-b">（二）收集、使用个人信息目的变更
        </p>
        <p>
          我们可能随着业务的发展，会对产品有所调整。当新功能或服务与注册、直播、音视频推荐、广告推送、发布图文、账号推荐、互动交流、搜索、查询、认证、虚拟财产等场景相关时，收集与使用的个人信息属于与原目的具有直接关联。在与原目的无直接关联的场景下，我们收集、使用您的个人信息，会再次征得您的同意。

        </p>
        <p class="f-b">三、我们如何使用您的个人信息</p>

        <p class="f-b">（一）为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账号安全，我们会在以下情形中使用您的信息：</p>
        <p>1、实现本政策中“我们如何收集您的个人信息”所述目的。</p>
        <p>2、为了使您知晓使用我们服务的状态，我们会向您发送服务通知及提醒。</p>
        <p>3、根据法律法规或监管要求向相关部门进行报告。</p>
        <p>4、邀请您参与我们服务有关的客户调研。</p>
        <p>5、为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途。</p>
        <p>
          6、当您申请佳伴推荐的产品时，我们可能需要基于规则或者机器学习等技术手段生成您的风险特征信息。该类特征信息是基于本政策第一条所列收集的您的信息经匿名化或去标识化加工或整理后生成。该风险特征信息，我们将用于反欺诈、信用评估、逾期风险控制等为您提供产品或服务相关的目的。
        </p>
        <p>
          （二）为了更好地向您提供服务，我们可能将某些服务中合法收集的您的个人信息经去标识化处理后结合起来，进行数据分析以形成用户画像，用来向您推荐您可能感兴趣的产品或服务。我们可能通过电话、手机短信、站内信、站内通知等形式向您推送我们认为您可能会感兴趣的个性化服务通知信息或商业性电子信息。
        </p>
        <p>如果您拒绝使用该服务，您可以退订该功能，我们将停止提供该服务，但不影响您使用我们提供的其他服务。
        </p>
        <p>（三）当我们展示您的个人信息时，我们会采用去标识化处理方式对您的信息进行脱敏展示，以保护您的信息安全。
        </p>
        <p>（四）当我们要将您的个人信息用于本政策未载明的其它用途时，我们会按照法律规定通过确认协议、文案确认等形式再次征求您的明确授权同意。若您不同意再次授权，您可能无法使用该项服务，但不影响您使用其他服务。
        </p>

        <p class="f-b">四、我们如何使用Cookie和同类技术
        </p>
        <p>（一）为实现您联机体验的个性化需求并使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件，指定给您的Cookies
          是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。Cookies
          通常包含标识符、站点名称以及一些号码和字符。我们向您发送Cookies是为了帮您节省重复输入注册、登录信息的步骤，帮助判断您的账户安全状态。
        </p>
        <p>（二）我们不会将 Cookies 用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookies。您可以清除计算机或移动设备上保存的所有 Cookies，还可以根据自己的需要来修改浏览器的设置以拒绝
          Cookies。但如果您这么做，您可能需要在每一次访问佳伴时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性产生一定影响。
        </p>

        <p class="f-b">五、我们如何共享、转让、公开披露个人信息
        </p>
        <p class="f-b">（一）如何共享个人信息</p>

        <p>除在下列情况外，我们不会与任何公司、组织和个人分享您的个人信息：</p>
        <p>1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
        </p>
        <p>2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
        </p>
        <p>3、与我们的附属公司共享：您的个人信息可能会与我们的附属公司共享。我们只会共享必要的个人信息，且受本政策中所声明目的的约束。附属公司如要改变个人信息的处理目的，将再次征求您的授权同意。
        </p>
        <p>
          4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。为提供更好的客户服务和用户体验，若经您授权同意后，我们可能将您的个人信息（包括本政策第一条所列收集的信息）提供给该获得您授权的第三方服务提供者。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息，我们的合作伙伴无权将共享的个人信息用于任何其他用途。
        </p>
        <p class="f-b">我们的授权合作伙伴包括以下类型：</p>
        <p>
          广告及分析服务类提供商。为了向您提供更优质的服务，对您进行活动推荐，我们可能会将您提供的手机号码、唯一设备识别码（IMEI、IMSI）、IP地址、地理位置共享给广告及分析服务类提供商，用于对您的产品需求/偏好进行评估，以向您推荐您需要的产品或服务，包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务或奖品、营销推广活动、支付便利或进行学术研究和调查。
        </p>
        <p>
          您可以拒绝提供此类信息，但不影响您使用其他服务。对我们与之共享个人信息的公司、组织和个人，我们会与其约定严格的保密条款，同时，我们会对其数据安全能力与环境进行评估并要求第三方以不低于本政策所要求的保密和安全措施来处理该些信息。要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。如果第三方因业务需要，确需超出前述授权范围使用个人信息的，该第三方将需再次征求您的同意。
        </p>
        <p>
          5、用户主动共享信息我们产品的多项服务可让您不仅与您的社交网络、也与使用该服务的所有用户公开分享您的相关信息，例如，您在产品中所上传或发布的信息（包括您公开的个人信息、您建立的名单）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。特别是产品的社交媒体服务，是专为使您可以与世界各地的用户共享信息而设计，从而使共享信息可实时、广泛的传递。只要您不删除共享信息，有关信息便一直留存在公众领域；即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存，或由其他用户或该等第三方在公众领域保存。您在我们的服务中所提供、上传或发布的内容和信息（例如有关您社交活动的照片或信息），可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否使用我们的服务披露您的敏感个人信息。
        </p>
        <p>
          因此，请您认真考虑您通过产品的服务上传、发布和交流的信息内容。在一些情况下，您可通过产品某些服务的隐私设定来控制有权浏览您的共享信息的用户范围。如您要求从产品的服务中删除您的个人信息，请通过该等特别服务条款提供的方式操作。
        </p>
        <p class="f-b">（二）如何转让个人信息
        </p>
        <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>1、在获取明确同意的情况下转让：在告知并获得您的明确同意后，我们会向经您同意的其他方转让您的个人信息；
        </p>
        <p>2、在涉及合并、收购、破产清算或类似交易时，如涉及到您个人信息转让，我们会向您告知有关情况，同时我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>3、基于法律法规、法律程序、诉讼或政府主管部门的强制性要求。
        </p>
        <p class="f-b">（三）委托处理
        </p>
        <p>我们服务中的某些具体的模块或功能可能由外部供应商提供，例如我们会聘请服务提供商来协助我们提供客户支持。
        </p>
        <p>对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <p class="f-b">（四）如何披露个人信息</p>

        <p>我们仅会在以下情况下，公开披露您的个人信息并会采用符合行业内标准的安全保护措施：</p>
        <p>1、获得您明确同意后，以您明确同意的披露方式披露您所指定的个人信息；</p>
        <p>2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>

        <p>3、对违规账号、欺诈行为进行处罚公告。</p>

        <p class="f-b">（五）免征得同意共享、转让、公开披露的个人信息</p>

        <p>根据法律法规及国家标准，我们在下列情形中，共享、转让、公开披露您的个人信息无需征得您的授权同意：</p>
        <p>1、与国家安全、国防安全直接相关的；</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
        <p>4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您的同意的；</p>
        <p>5、您自行向社会公众公开的个人信息；</p>
        <p>6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>7、与个人信息控制者履行法律法规规定的义务相关的。
        </p>

        <p class="f-b">六、我们如何保护个人信息
        </p>
        <p>
          本政策提供的如下个人信息保护措施仅适用于我们的产品。一旦您离开我们的产品，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在我们的产品之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于我们的产品的链接或引导
        </p>
        <p class="f-b"> （一）为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。
        </p>
        <p class="f-b">我们会采取一切合理可行的措施，保护您的个人信息。例如：</p>
        <p>1、在您的设备与“产品服务”之间交换数据时受SSL加密保护;</p>
        <p>2、我们会使用加密技术确保数据的保密性;</p>
        <p>3、我们会使用受信赖的保护机制防止数据遭到恶意攻击;</p>
        <p>4、我们会部署访问控制机制，确保只有授权人员才可访问个人信息;</p>
        <p>5、我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识；</p>
        <p>6、我们认为其他的合理可行的保护措施。
        </p>
        <p class="f-b">（二）我们已经通过了国家信息安全等级保护（三级）的测评和备案。
        </p>
        <p class="f-b">（三）我们会使用不低于行业同行的加密技术、匿名化处理等合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
        </p>
        <p class="f-b">
          （四）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。我们仅在为提供产品服务之目的所必需的期间内保留您的个人信息，例如，您发布的音视频等信息、评论、点赞等信息，在您未撤回、删除或未注销账号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。
        </p>
        <p class="f-b">
          （五）互联网并非绝对安全的环境，而且即时通讯及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。请您务必妥善保管好您的产品登录名及其他身份要素。您在使用产品时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。如您发现产品登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
        </p>
        <p class="f-b">（六）我们将不定期更新并公开安全风险、个人信息安全影响评估等报告的有关内容。
        </p>
        <p class="f-b">
          （七）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p class="f-b">
          （八）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        </p>

        <p class="f-b">七、您如何管理自己的个人信息（您的权利）
        </p>
        <p>我们非常重视您对您个人信息的管理，按照中华人民共和国相关的法律、法规、国家标准，以及行业通行做法，我们保障您对自己的个人信息行使以下权利，您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务：
        </p>
        <p class="f-b">（一）访问您的个人信息</p>

        <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
        </p>
        <p>账号信息——如果您希望访问或编辑您的账号中的个人资料信息、更改您的密码、添加安全信息等，您可以通过产品客户端应用程序中点击右下方的「我」，通过点击右上方「编辑个人资料」或「设置」点击执行相应操作。
        </p>
        <p>如果您无法通过上述方式访问上述个人信息，您可以使用发送电子邮件（佳伴官方邮箱地址：aizhuiguang@163.com）的方式联系我们。
        </p>
        <p>我们将在15个工作日内回复您的访问请求。
        </p>
        <p class="f-b">（二）更正您的个人信息
        </p>
        <p>
          一般情况下，您可以直接删除您发布的内容，随时浏览、修改、删除自己提交的信息。但出于安全性和身份识别的考虑，您可能无法自主修改注册时提交的某些初始注册信息（如实名认证后信息）；如您确有必要修改该类信息，您可以使用发送电子邮件（佳伴官方邮箱地址：aizhuiguang@163.com）或在线客服的方式进行更正。
        </p>
        <p>我们将在15个工作日内回复您的访问请求。</p>

        <p class="f-b">（三）删除您的个人信息</p>

        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>1、如果我们处理个人信息的行为违反法律法规;</p>
        <p>2、如果我们收集、使用您的个人信息，却未征得您的同意;</p>
        <p>3、如果我们处理个人信息的行为违反了与您的约定;</p>
        <p>4、如果您不再使用我们的产品或服务;</p>
        <p>5、如果我们不再为您提供产品或服务。</p>

        <p>若您需要向我们提出删除个人信息的请求或在注销账户的过程中遇到问题，您可以使用发送电子邮件（佳伴官方邮箱地址：aizhuiguang@163.com）进行咨询。
        </p>
        <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <p>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        <p>我们将在15个工作日内回复您的删除请求。
        </p>
        <p class="f-b">（四）管理您的授权范围
        </p>
        <p>1、每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
        </p>
        <p class="f-b">您可以通过以下方式自行操作：</p>
        <p>您可以通过删除信息、关闭设备功能、在产品客户端应用程序中通过「设置」-「账号与安全、通知提醒、隐私」，改变您在功能和服务中的授权范围。
        </p>
        <p>特定的业务功能和服务将需要您的个人信息才能得以完成，当您撤回同意后，我们无法继续为您提供撤回同意所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意的决定，不会影响我们此前基于您的同意而开展的个人信息处理。
        </p>
        <p>当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p class="f-b">2、如果您不想接收我们给您发送的个性化服务信息，您随时可通过以下方式取消：
        </p>
        <p>（1）您可以回复“T”或“TD”（具体以短信内容中提示为准）来取消我们给您发送的个性化服务短信；
        </p>
        <p>（2）您可以在我们移动端APP“个人中心-设置（菜单栏图标）中取消个性化服务；
        </p>
        <p>（3）您可以通过发送电子邮件（佳伴官方邮箱地址：aizhuiguang@163.com）要求取消个性化服务（站内信、站内通知、短信、电话）；
        </p>
        <p>我们将在15个工作日内回复您的撤回授权请求。
        </p>
        <p class="f-b">（五）注销账号
        </p>
        <p>
          您随时可注销此前注册的账号，在注销账号前，我们将验证您的个人身份、安全状态、设备信息等。注销账号的行为是不可逆的行为，当注销账号后，我们将删除有关您产品账号的一切信息或对相关信息进行匿名化处理，但法律法规另有规定的除外。
        </p>
        <p>注销方式1：您可以通过邮件方式（佳伴官方邮箱地址：aizhuiguang@163.com）向我们申请注销账号。
        </p>
        <p>注销方式2：您可以在我们移动端APP“个人中心-设置（菜单栏图标）中自主注销。
        </p>
        <p class="f-b">（六）约束信息系统自动决策
        </p>
        <p>
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将在不侵害佳伴商业秘密或其他用户权益、社会公共利益的前提下提供适当的救济方式。
        </p>
        <p class="f-b">（七）我们如何响应您的请求
        </p>
        <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
        </p>
        <p>
          我们将在三十个工作日内做出答复。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </p>
        <p class="f-b">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
        <p>1、国家安全、国防安全直接相关的;</p>
        <p>2、与公共安全、公共卫生、重大公共利益直接相关的;</p>
        <p>3、与犯罪侦查、起诉、审判和判决执行等直接相关的;</p>
        <p>4、有充分证据表明您存在主观恶意或滥用权利的;</p>
        <p>5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
        <p>6、涉及商业秘密的。</p>

        <p class="f-b">如果我们决定不响应您的请求，将向您告知该决定的理由，并向您提供投诉的途径。
        </p>
        <p class="f-b">（八）投诉举报
        </p>
        <p>您可以按照我们公示的制度进行投诉或举报。如果您认为您的个人信息权利可能受到侵害，或者发现侵害个人信息权利的线索，您可以通过如下途径进行举报，我们核查后会在15个工作日内反馈您的投诉与举报：
        </p>
        <p>1、点击侵权用户右上角[更多]，进入举报界面与我们联系；</p>
        <p>2、在「我」界面，点击联系客服与我们联系；</p>
        <p>3、通过邮件aizhuiguang@163.com与我们联系。
        </p>
        <p class="f-b">（九）访问隐私政策
        </p>
        <p>您可以在注册、登录页面，或者登录后依次点击「我」-「设置」-「用户使用协议」后，查看本政策的全部内容。
        </p>
        <p class="f-b">（十）停止运营向您告知权利
        </p>
        <p>如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。

        </p>
        <p class="f-b">八、我们如何处理未成年人的个人信息
        </p>
        <p>（一）我们非常重视对未成年人个人信息的保护。我们的服务面向年满18周岁的成年人（且非为在校学生）。若您是18周岁以下的未成年人，我们建议您不要使用我们的服务。
        </p>
        <p>（二）若您必须要使用我们的服务时，应事先取得您的父母或法定监护人的书面同意或由您的父母或法定监护人代替您操作。我们将根据国家相关法律法规的规定保护未成年人的个人信息。
        </p>
        <p>（三）对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。
        </p>
        <p>（四）若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请及时与我们联系。
        </p>
        <p>（五）如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人个人信息的，则我们会设法尽快删除相关数据。

        </p>
        <p class="f-b">九、您个人信息的存储地点及存储时间
        </p>
        <p>
          （一）我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。我们不会将上述信息传输至境外，如果部分产品涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们将会遵循国家相关规定，征求您的同意，并按照法律法规和相关监管部门的规定执行，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。
        </p>
        <p>（二）我们仅在为实现本服务目的所必需的最短时间内保留您的个人信息，法律法规另有规定或者您另行授权同意的除外。
        </p>
        <p >如超出上述保存期限，我们会将您的信息删除或匿名化处理。
        </p>
        <p>（三）如果我们终止服务或运营，我们会至少提前十五个工作日向您通知，并在终止服务或运营后对存储的您的个人信息进行删除或匿名化处理。

        </p>
        <p class="f-b">十、对第三方责任的声明
        </p>
        <p>请您注意，本政策仅适用于佳伴自行向您提供的产品或服务。
        </p>
        <p>
          如果第三方服务商通过佳伴为您提供服务，当您进入第三方服务商的服务页面时，请注意相关服务由第三方向您提供。涉及到第三方向您收集信息的，建议您仔细查看第三方的隐私政策或协议约定。当您查看第三方的服务页面（包括第三方开发的应用程序或第三方创建的网页）时，这些第三方可能会放置自己的Cookie或其同类技术，这些Cookie或其同类技术不受我们的控制，且其使用不受本政策的约束。我们将努力要求上述第三方主体对您的个人信息采取保护措施。如您发现上述第三方应用程序（或网页）存在风险时，建议您立即终止相关操作。
        </p>
        <p>
          请您知悉，佳伴APP的部分服务需要由我们的合作伙伴共同为您提供，因此，佳伴APP内将嵌入合作伙伴的软件工具开发包（以下简称“SDK或API”），我们将逐项列举嵌入SDK/API的名称、目的、收集信息类型、获取权限，具体详见附件一。

        </p>
        <p class="f-b">十一、本政策的适用及修订
        </p>
        <p>
          （一）佳伴所有服务均适用本政策，但某些特定服务还将适用特定的隐私保护政策（例如授权书、授权委托书），我们将在向您提供这些特定服务前进一步向您说明并获取您的授权同意。如特定的隐私保护政策与本政策有不一致之处，以特定的隐私保护政策为准。特定的隐私保护政策未提及之处，以本隐私保护政策为准。
        </p>
        <p>（二）我们的个人信息保护政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
        </p>
        <p>
          对于重大变更，我们还会提供更为显著的通知。如本政策发生重大变更，我们将在佳伴官方发布公告或以推送通知、发送短信、拨打电话的方式来通知您，并于本政策更新后再次获取您对于变更后隐私政策的明示授权同意。为了您能及时接收到通知，建议您在手机号码更新时及时通知我们。若您在本政策修订且接到我们的通知后，点击确认或下一步，这表示您已充分阅读、理解并接受修订后的本政策。
        </p>
        <p class="f-b">本政策所指的重大变更包括但不限于：</p>

        <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>

        <p>2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>

        <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>

        <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
        </p>
        <p>5、 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
        </p>
        <p>6、个人信息安全影响评估报告表明存在高风险。
        </p>

        <p class="f-b">十二、如何联系我们
        </p>
        <p class="f-b">（一）如果您对本政策或您在使用我们的服务过程中有任何疑问、意见或建议，您均可通过以下方式与我们取得联系：</p>
        <p>公司名称：海南悠蓝网络科技有限公司</p>
        <p>联系地址：深圳市南山区月亮湾大道2076号高科集团大楼7楼78087；</p>
        <p>联系邮箱：aizhuiguang@163.com
        </p>
        <p class="f-b">（二）为了保障您的信息要求，我们处理您的问题前，需要先验证您的身份，但我们最长将自您提出疑问、意见或建议之日起不超过15个工作日内予以回复。
        </p>

        <p class="f-b">十三、本政策中的关键词说明
        </p>
        <p>（一）佳伴App产品或服务：指我们及关联方通过合法拥有并运营的、标注名称包含“佳伴”的客户端应用程序，向您提供的产品与服务，包括但不限于聊天、音视频发布、图文发布及相应浏览、推荐、互动等功能。
        </p>
        <p>
          （二）个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本政策个人信息包括姓名、出生日期、身份证件号码、个人生物识别信息、住址、通信通讯联系方式、通信记录和内容、账号密码、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、网络身份识别信息、常用设备信息、交易信息、手机号码、微信号、QQ号等。
        </p>
        <p>
          （三）个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本政策中的个人敏感信息包括身份证件号码、邮箱地址、各类密码（口令、保护答案、数字证书）、个人生物识别信息、通记记录和内容、个人健康生理信息、个人财产信息、银行账号、征信信息、行踪轨迹、住宿信息、交易信息、14岁周岁以下（含）儿童的个人信息等。
        </p>
        <p>（四）身份要素：指产品用于识别您身份的信息要素，例如：您的登录名、密码、短信校验码、电话号码、手机号码、证件号码及个人生物识别信息（例如：指纹信息、脸部信息）。
        </p>
        <p>
          （五）非个人信息：指通过对个人信息的技术处理（包括匿名化、去标识化等），使得处理后的信息无法单独或结合其他信息识别到您的个人身份，且处理后的信息不能被复原，处理后的信息不属于法律意义上您的个人信息。但当个人信息与处理后的信息结合使用期间，这些结合信息将作为您的个人信息按照本政策处理与保护。
        </p>
        <p>（六）用户：指注册使用佳伴的个人用户，以下称“您”或“最终用户”。
        </p>

        <p class="f-b">十四、其他说明
        </p>
        <p>（一）本政策中的标题仅为方便及阅读而设，并不影响本政策中任何规定的含义或解释。
        </p>
        <p>（二）本政策中所述的我们的产品有可能会根据您所使用的手机型号、系统版本、软件应用程序版本等因素而有所不同。最终的产品和服务以您所使用的产品为准。
        </p>
        <p>（三）本政策的版权为我们所有，我们拥有修改的权利。</p>


        <p class="f-b">十五、《附件一：佳伴Android 版本第三方SDK/API目录》</p>

        <p>
          为保障相关功能的实现以及您的知情权，我们与第三方数据合作的详情将做如下展示。我们将在您知情同意的前提下妥善管理数据，确保数据的全流程安全。同时，我们将根据产品的升级以及与第三方数据合作的变动对如下内容进行调整，您可以随时查看。
        </p>
        <p>
          为保障App的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们接入的部分第三方SDK可能涉及收集用户信息，用于向用户提供服务。我们会对第三方提供的软件开发包（SDK）进行严格的安全检测，以保护用户信息安全。
        </p>
        <p>
          为向用户提供更多服务、保证服务稳定性和质量或升级相关功能时，我们可能需要调整我们接入的第三方SDK，我们会及时在本页面向您说明收集用户信息的第三方SDK的最新情况。第三方SDK可能因为其版本升级、策略调整等原因调整第三方SDK的数据处理，您可在第三方SDK官网查看。
        </p>
        <p class="f-b">1、登录类服务SDK</p>

        <p>SDK名称：微信开放平台</p>
        <p>公司名称：腾讯科技（深圳）有限公司</p>
        <p>SDK官网：https://open.weixin.qq.com</p>
        <p>收集个人信息目的或用途：用于支持微信登录</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>

        <p class="f-b">2、社交服务SDK</p>

        <p>SDK名称：网易云信（com.netease）</p>
        <p>公司名称：杭州网易质云科技有限公司</p>
        <p>SDK官网：https://yunxin.163.com</p>
        <p>收集个人信息目的或用途：用于帮助用户发送聊天消息、音视频通话</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>


        <p class="f-b">3、支付类服务SDK</p>

        <p>SDK名称：微信开放平台</p>
        <p>公司名称：腾讯科技（深圳）有限公司</p>
        <p>SDK官网：https://open.weixin.qq.com</p>
        <p>收集个人信息目的或用途：用于实现微信支付服务</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>

        <p>SDK名称：支付宝</p>
        <p>公司名称：支付宝（中国）网络技术有限公司</p>
        <p>SDK官网：https://open.alipay.com/</p>
        <p>收集个人信息目的或用途：用于实现支付宝支付服务</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>


        <p class="f-b">4、推送通知服务SDK</p>
        <p>SDK名称：极光推送（cn.jpush.android）</p>
        <p>公司名称：深圳市和讯华谷信息技术有限公司</p>
        <p>SDK官网：https://developer.umeng.com/sdk</p>
        <p>收集个人信息目的或用途：用于实现消息推送</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>

        <p>SDK名称：小米推送</p>
        <p>公司名称：小米科技有限责任公司</p>
        <p>SDK官网：https://dev.mi.com/console/appservice/push.html</p>
        <p>收集个人信息目的或用途：用于实现对小米品牌的手机进行消息推送</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>

        <p>SDK名称：VIVO推送</p>
        <p>公司名称：广东步步高电子工业有限公司</p>
        <p>SDK官网：https://dev.vivo.com.cn/home</p>
        <p>收集个人信息目的或用途：用于实现对VIVO品牌的手机进行消息推送</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>

        <p>SDK名称：OPPO推送平台</p>
        <p>公司名称：OPPO广东移动通信有限公司</p>
        <p>SDK官网：https://push.oppo.com/</p>
        <p>收集个人信息目的或用途：用于实现对OPPO品牌的手机进行消息推送</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>

        <p>SDK名称：华为推送</p>
        <p>公司名称：华为技术有限公司</p>
        <p>SDK官网：https://developer.huawei.com</p>
        <p>收集个人信息目的或用途：用于实现对华为品牌的手机进行消息推送</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>


        <p class="f-b">6、统计分析类服务SDK</p>

        <p>SDK名称：友盟统计</p>
        <p>公司名称：北京锐讯灵通科技有限公司</p>
        <p>SDK官网：https://developer.umeng.com/docs/119267/cate/223529</p>
        <p>收集个人信息目的或用途：用于统计数据和效果分析，以便为用户提供更好的服务</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>


        <p class="f-b">7、其他类服务SDK</p>

        <p>SDK名称：BuglySDK</p>
        <p>公司名称：腾讯科技（深圳）有限公司</p>
        <p>SDK官网：https://bugly.qq.com/docs/user-guide/instruction-manual-android/?v=20180312155926</p>
        <p>收集个人信息目的或用途：用于监测APP稳定性并进行故障诊断、崩溃上报，以便帮助用户快速解决异常情况</p>
        <p>收集个人信息类型：设备信息（设备型号、系统版本、操作系统、设备厂商）、网络信息、IMEI、IMSI、MAC地址</p>
        <p>使用到的权限：存储权限、读取设备信息、读取网络状态、读取WIFI状态</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlQueryString } from '@/utils/util'

export default {
  name: 'PrivacyPage',
  data() {
    return {
      app_name: '佳伴'
    }
  },
  created() {
    document.title = '隐私政策'
    this.app_name = getUrlQueryString('app_name')
  },
  methods: {}
}
</script>

<style lang="less">
#app {
  text-align: left;
  .pol {
    line-height: 80px;
    padding: 20px;
    box-sizing: border-box;

    .title {
      font-weight: bold;
      text-align: center;
      padding: 20px 0px;
    }
  }
}

</style>